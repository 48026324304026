export interface IDeviceItinerary {
	blocks: IDeviceItineraryBlock[];
	hash?: string;
	operationModeId?: number;
}

export interface IDeviceItineraryBlock {
	start: Date;
	end: Date;
	sequence: IDeviceItineraryLayout[];
}


export interface IDeviceItineraryTemplate {
	path: string;
	widgets: Array<IDeviceItineraryWidget | IDeviceItineraryWidgetContainer>;
	configuration: IDeviceItineraryTemplateConfiguration;
}

export interface IDeviceItineraryWidget {
	events?: any[];
	path: string;
	target: string;
	configuration: IDeviceItineraryWidgetConfiguration;
}

export interface IDeviceItineraryWidgetContainer {
	path: string;
	target: string;
	configuration: IDeviceItineraryWidgetContainerConfiguration;
}

export interface IDeviceItineraryLayout {
	template: IDeviceItineraryTemplate;
	timing: IDeviceItineraryLayoutTiming;
	manifest?: IDeviceItineraryManifest;
	events?: IDeviceItineraryConfigurationEvent[];
}

export interface IDeviceItineraryManifest {
	bundles: IDeviceItineraryManifestBundle[];
}

export interface IDeviceItineraryManifestBundle {
	name: string;
	path: string;
	exports: string[];
}

export interface IDeviceItineraryLayoutTiming {
	duration?: number;
	repetitions: number;
}


export interface IDeviceItineraryTemplateConfiguration {
	offscreenConfiguration?: any;
	debug?: boolean;
	holding?: boolean;
	[x: string]: any
}

export interface IDeviceItineraryWidgetConfiguration {
	debug?: boolean;
	name?: string;
	[x: string]: any
}

export interface IDeviceItineraryWidgetConfigurationStateConfiguration {
	[x: string]: any
}

export interface IDeviceItineraryWidgetConfigurationState {
	name: string;
	priority?: number;
	widgets: Array<IDeviceItineraryWidget | IDeviceItineraryWidgetContainer>;
	configuration: IDeviceItineraryWidgetConfigurationStateConfiguration;
	events?: IDeviceItineraryConfigurationEvent[];
}

export interface IDeviceItineraryConfigurationEvent {
	name: string;
	dataMatch?: any | any[];
	returnToDefault?: boolean;
}

export interface IDeviceItineraryConfigurationEventEvaluationResponse {
	event?: IDeviceItineraryConfigurationEvent;
	isValid: boolean;
}

export interface IDeviceItineraryWidgetContainerConfiguration {
	debug?: boolean;
	states: Array<IDeviceItineraryWidgetConfigurationState>;
	[x: string]: any
}
